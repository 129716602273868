import { createAction, props } from '@ngrx/store';

export const toggleTabPosition = createAction(
  '[Profile Page] Toggle Tab Position',
  props<{ tabPosition: 'top' | 'left' }>(),
);

export const updateLanguage = createAction(
  '[Profile Page] Update Language',
  props<{ language: string }>(),
);

export const updateTeacherPIN = createAction(
  '[Profile Page] Update Teacher PIN',
  props<{ pin: string }>(),
);
