{
  "cognito": {
    "userPools": {
      "parent": {
        "url": "https://cogup-ext-parent-auth.waterford.org",
        "clientId": "64p9vkthsfne2oli7ud3rnm843"
      },
      "upstartParent": {
        "url": "https://cogup-ext-parent-auth.waterford.org",
        "clientId": "4pf9tbevrp645ulsivknpc5bio"
      },
      "newUpstartParent": {
        "url": "https://cogup-ext-parent-auth.waterford.org",
        "clientId": "6t8qda2e34goopqi6keg1pk1u0"
      }
    }
  },
  "api": {
    "region": "us-east-1",
    "key": "wIkpRlPdhJCfEsK9F8WhIB5rg5BJ1LOSaYeCDvtx",
    "url": "api.waterfordlabs.org",
    "protocol": "https"
  },
  "dashboards": {
    "url": "https://dashboard-plugin.waterford.org/wel_manager"
  },
  "reporting": {
    "jasperUrl": "https://jasper.waterford.org"
  },
  "content": {
    "url": "https://content.waterford.org",
    "cloudFrontUrl": "https://d1hsa6e2n2ycln.cloudfront.net",
    "videoTemplateId": "262"
  },
  "contentV3": {
    "url": "https://content-v3.waterford.org"
  },
  "resources": {
    "url": "https://resources.waterford.org"
  },
  "analytics": {
    "student": {
      "url": "https://stats.waterford.org/",
      "siteId": 4,
      "enabled": true
    },
    "parent": {
      "url": "https://stats.waterford.org/",
      "siteId": 5,
      "enabled": true,
      "dimensions": {
        "languageIndex": 1
      },
      "eventDimensions": {
        "entityTypeIndex": 10,
        "entityStatusIndex": 11,
        "rangeStartIndex": 12,
        "rangeEndIndex": 13,
        "viewLevelIndex": 14,
        "curriculumIndex": 15,
        "organizationIdIndex": 5,
        "schoolIdIndex": 6,
        "classIdIndex": 7,
        "entityIdIndex": 8,
        "entityNameIndex": 9
      }
    },
    "staff": {
      "url": "https://stats.waterford.org/",
      "siteId": 6,
      "enabled": true,
      "dimensions": {
        "orgIdIndex": 1,
        "schoolIdIndex": 2,
        "roleIndex": 3,
        "languageIndex": 4
      },
      "eventDimensions": {
        "entityTypeIndex": 10,
        "entityStatusIndex": 11,
        "rangeStartIndex": 12,
        "rangeEndIndex": 13,
        "viewLevelIndex": 14,
        "curriculumIndex": 15,
        "organizationIdIndex": 5,
        "schoolIdIndex": 6,
        "classIdIndex": 7,
        "entityIdIndex": 8,
        "entityNameIndex": 9
      }
    },
    "admin": {
      "url": "https://stats.waterford.org/",
      "siteId": 7,
      "enabled": true
    }
  },
  "fusion": {
    "loginUrl": "https://signin.waterford.org",
    "adminLoginUrl": "https://adminsignin.waterford.org",
    "userLookupUrl": "https://api.waterford.org/user-lookup/v2"
  },
  "helpMessages": {
    "wacs": {
      "en": {
        "url": "https://resources.waterford.org/content/2177/2177.html"
      },
      "es": {
        "url": "https://resources.waterford.org/content/2178/2178.html"
      }
    }
  },
  "sentry": {
    "dsn": "https://94409c0c04f042bfb878ac83c2caee05@o1133904.ingest.sentry.io/4504084366229504",
    "environment": "prod",
    "tracesSampleRate": 0.1
  },
  "upstartRegistration": {
    "wizardCss": "https://mentor.waterford.org/upstart-registration/assets/waterford-wizard.css",
    "programsCss": "/assets/styles/forms/waterford-forms.css"
  },
  "notifications": {
    "websocketUrl": "wss://ws-api.waterfordlabs.org/notification-websockets"
  },
  "googleAnalytics": {
    "enabled": true
  },
  "recaptcha": {
    "siteKey": "6LfiAsghAAAAAOeIMwvwDmctBMSjcrlEnZEwFhHf"
  },
  "upstartLogin": {
    "signUpUrl": "https://www.waterford.org/upstart/",
    "enabled": true
  },
  "snowplow": {
    "collectorUrl": "https://snowplow.waterford.org"
  },
  "eula": {
    "en": "https://resources.waterford.org/data-source/4579/4579.html",
    "es": "https://resources.waterford.org/data-source/4580/4580.html"
  }
}
