<h1 mat-dialog-title>
  {{ 'Contact us' | translate }}
</h1>
<div
  id="contact-us-content"
  mat-dialog-content>
  <form
    #contactForm="ngForm"
    #form
    action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
    class="contact-us__form"
    method="POST"
    target="requestTarget">
    <input
      name="orgid"
      ngModel="00D0b000000vtHK"
      type="hidden" />
    <input
      [ngModel]="returnUrl"
      name="retURL"
      type="hidden" />

    <div class="contact-us__form__row">
      <input
        [ngModel]="name$ | async"
        id="name"
        name="name"
        placeholder="Name"
        required
        type="text" />
      <input
        [ngModel]="email$ | async"
        id="email"
        name="email"
        placeholder="Email"
        required
        type="text" />
    </div>
    <div class="contact-us__form__row">
      <input
        autofocus
        id="subject"
        name="subject"
        ngModel
        placeholder="Subject"
        type="text" />

      <select
        id="00N0b00000BXwHX"
        name="00N0b00000BXwHX"
        ngModel>
        <option value="">{{ 'Select request type' | translate }}</option>
        <option value="Technical Problem">{{ 'Technical Problem' | translate }}</option>
        <option value="Document Request">{{ 'Document Request' | translate }}</option>
        <option value="General Support">{{ 'General Support' | translate }}</option>
      </select>
    </div>
    <div class="contact-us__form__row contact-us__form__row--single">
      <input
        [ngModel]="children$ | async"
        id="00N0b00000BsQHM"
        name="00N0b00000BsQHM"
        placeholder="Children"
        type="text" />
    </div>
    <div class="contact-us__form__row contact-us__form__row--single">
      <textarea
        id="description"
        name="description"
        ngModel
        placeholder="Description"
        required></textarea>
    </div>
  </form>
</div>
<div mat-dialog-actions>
  <cl-button
    (click)="dialog.close()"
    buttonStyle="secondary"
    id="open-message-close-button">
    {{ 'Cancel' | translate }}
  </cl-button>
  <cl-button
    (click)="form.submit(); dialog.close()"
    [disabled]="!contactForm.form.valid"
    id="open-message-submit-button">
    {{ 'Submit' | translate }}
  </cl-button>
</div>
