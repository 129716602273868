import { Injectable } from '@angular/core';
import * as profileActions from './state/profile.actions';
import { Store } from '@ngrx/store';
import { State } from '@wam/shared';
import { MatDialog } from '@angular/material/dialog';
import { ContactUsDialogComponent } from './contact-us-dialog/contact-us-dialog.component';
import { ApiGatewayService } from '@wam/authentication';
import { WithAuthService } from '@app/shared/services/with-auth.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProfileService extends WithAuthService {
  constructor(
    private dialog: MatDialog,
    public store: Store<State>,
    private apiGatewayService: ApiGatewayService,
  ) {
    super(store);
  }

  toggleLanguage(language: string): void {
    this.store.dispatch(profileActions.updateLanguage({ language }));
  }

  toggleTabPosition(tabPosition: 'top' | 'left'): void {
    this.store.dispatch(profileActions.toggleTabPosition({ tabPosition }));
  }

  openContactUsDialog(): void {
    this.dialog.open(ContactUsDialogComponent, {
      width: '800px',
      maxHeight: '85vh',
      panelClass: 'no-padding-dialog',
      autoFocus: false,
    });
  }

  getProgramEnrollments(): Observable<{ id: string }[]> {
    return this.withUser().pipe(
      switchMap(() =>
        this.apiGatewayService.get<{ id: string }[]>(
          `enrollment-v2/v2/apps/waterford-upstart/orgs/upstart/programEnrollments`,
        ),
      ),
    );
  }
}
