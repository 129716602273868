import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { authenticationSelectors } from '@wam/authentication';
import { map } from 'rxjs/operators';
import { Claim, State, User } from '@wam/shared';
import * as fromClaims from '@app/claims/state/claims.selectors';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss'],
})
export class ContactUsDialogComponent {
  name$: Observable<string> = this.store.pipe(select(authenticationSelectors.getName));
  email$: Observable<string> = this.store.pipe(
    select(authenticationSelectors.getCurrentUser),
    map((user: User) => user.email),
  );
  children$: Observable<string> = this.store.pipe(
    select(fromClaims.getClaims),
    map((claims: Claim[]) => claims.map((claim) => claim.resourceName).join(', ')),
  );

  constructor(
    private store: Store<State>,
    public dialog: MatDialogRef<ContactUsDialogComponent>,
  ) {}

  get returnUrl(): string {
    return window.location.href;
  }
}
